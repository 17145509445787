a.cke_dialog_ui_button_ok {
    color: #fff !important;
    background: $ckeditor-button-bg !important;
    border: 1px solid $ckeditor-button-border !important;
}
.cke{
	flex-shrink: 0;
}
.texteditor-lang{
	select, .chosen-container{
		margin-left: 10px;
	}
}