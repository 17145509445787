$gutter:2%;
.grid{
    &:before,
    &:after{
        display: table;
        line-height: 0;
        content: "";
    }
    &:after { clear: both; }
    [class*="span"] {
        display: block;
        float: left;
        width: 100%;
        min-height: 5px;
        margin-left:$gutter;
        // margin-left: 2.127659574468085%;
        box-sizing: border-box;
        &:first-child { margin-left: 0; }
    }
    .span1 { width: function-calc((100%/12-$gutter)+($gutter/12)); } // 100%/12-$gutter
    .span2 { width: function-calc((100%/6-$gutter)+($gutter/6)); } // 100%/6
    .span3 { width: function-calc((100%/4-$gutter)+($gutter/4)); } // 100%/4
    .span4 { width: function-calc((100%/3-$gutter)+($gutter/3)); } // 100%/3 
    .span5 { width: function-calc((100%/2.4-$gutter)+($gutter/2.4)); } // 100%/2,4
    .span6 { width: function-calc((100%/2-$gutter)+($gutter/2)); } // 100%/2    
    .span7 { width: function-calc((100%/1.714285714285714-$gutter)+($gutter/1.714285714285714)); } // 100%/1,714285714285714
    .span8 { width: function-calc((100%/1.5-$gutter)+($gutter/1.5)); } // 100%/1,5
    .span9 { width: function-calc((100%/1.333333333333333-$gutter)+($gutter/1.333333333333333)); } // 100%/1,333333333333333
    .span10 { width: function-calc((100%/1.2-$gutter)+($gutter/1.2)); } // 100%/1,2
    .span11 { width: function-calc((100%/1.090909090909091-$gutter)+($gutter/1.090909090909091)); } // 100%/1,090909090909091
    .span12 { width: function-calc((100%/1-$gutter)+($gutter/1)); } // 100%/1
}

.grid-fluid{
    &:before,
    &:after{
        display: table;
        line-height: 0;
        content: "";
    }
    &:after { clear: both; }
    [class*="span"] {
        display: block;
        float: left;
        width: 100%;
        margin-left:0;
        min-height: 5px;
        box-sizing: border-box;
        &:first-child { margin-left: 0; }
    }
    .span1 { width: function-calc(100%/12); } // 100%/12
    .span2 { width: function-calc(100%/6); } // 100%/6
    .span3 { width: function-calc(100%/4); } // 100%/4
    .span4 { width: function-calc(100%/3); } // 100%/3 
    .span5 { width: function-calc(100%/2.4); } // 100%/2,4
    .span6 { width: function-calc(100%/2); } // 100%/2    
    .span7 { width: function-calc(100%/1.714285714285714); } // 100%/1,714285714285714
    .span8 { width: function-calc(100%/1.5); } // 100%/1,5
    .span9 { width: function-calc(100%/1.333333333333333); } // 100%/1,333333333333333
    .span10 { width: function-calc(100%/1.2); } // 100%/1,2
    .span11 { width: function-calc(100%/1.090909090909091); } // 100%/1,090909090909091
    .span12 { width: function-calc(100%/1); } // 100%/1
}


.row{
    @include clearfix;
    .col{
        float: left;
        min-height: 1px;
        &.m1{width: susy-span(1 of 12);}
        &.m2{width: susy-span(2 of 12);}
        &.m3{width: susy-span(3 of 12);}
        &.m4{width: susy-span(4 of 12);}
        &.m5{width: susy-span(5 of 12);}
        &.m6{width: susy-span(6 of 12);}
        &.m7{width: susy-span(7 of 12);}
        &.m8{width: susy-span(8 of 12);}
        &.m9{width: susy-span(9 of 12);}
        &.m10{width: susy-span(10 of 12);}
        &.m11{width: susy-span(11 of 12);}
        &.m12{width: susy-span(12 of 12);}
    }
    &.flex{
        &.between{
            &:after{
                display: none;
            }
        }
    }
}

.row-gutter{
  @include clearfix;
  .col{
    float: left;
    min-height: 1px;
    margin-right: gutter(of 12);
    &:last-child{
      margin-right: 0;
    }
    &.m1{width: susy-span(1 narrow of 12 narrow);}
    &.m2{width: susy-span(2 narrow of 12 narrow);}
    &.m3{width: susy-span(3 narrow of 12 narrow);}
    &.m4{width: susy-span(4 narrow of 12 narrow);}
    &.m5{width: susy-span(5 narrow of 12 narrow);}
    &.m6{width: susy-span(6 narrow of 12 narrow);}
    &.m7{width: susy-span(7 narrow of 12 narrow);}
    &.m8{width: susy-span(8 narrow of 12 narrow);}
    &.m9{width: susy-span(9 narrow of 12 narrow);}
    &.m10{width: susy-span(10 narrow of 12 narrow);}
    &.m11{width: susy-span(11 narrow of 12 narrow);}
    &.m12{width: susy-span(12 narrow of 12 narrow);}
  }
}

@media(max-width: 1023px) {
  .row{
    @include clearfix;
    .col{
      float: left;
      min-height: 1px;
      &.s1{width: susy-span(1 of 12);}
      &.s2{width: susy-span(2 of 12);}
      &.s3{width: susy-span(3 of 12);}
      &.s4{width: susy-span(4 of 12);}
      &.s5{width: susy-span(5 of 12);}
      &.s6{width: susy-span(6 of 12);}
      &.s7{width: susy-span(7 of 12);}
      &.s8{width: susy-span(8 of 12);}
      &.s9{width: susy-span(9 of 12);}
      &.s10{width: susy-span(10 of 12);}
      &.s11{width: susy-span(11 of 12);}
      &.s12{width: susy-span(12 of 12);}
    }
  }
}

@media(max-width: 768px) {
  .row{
    @include clearfix;
    .col{
      float: left;
      min-height: 1px;
      &.xs1{width: susy-span(1 of 12);}
      &.xs2{width: susy-span(2 of 12);}
      &.xs3{width: susy-span(3 of 12);}
      &.xs4{width: susy-span(4 of 12);}
      &.xs5{width: susy-span(5 of 12);}
      &.xs6{width: susy-span(6 of 12);}
      &.xs7{width: susy-span(7 of 12);}
      &.xs8{width: susy-span(8 of 12);}
      &.xs9{width: susy-span(9 of 12);}
      &.xs10{width: susy-span(10 of 12);}
      &.xs11{width: susy-span(11 of 12);}
      &.xs12{width: susy-span(12 of 12);}
    }
  }
}


