/* Select Dropdown style */
.dropdown--wrapper, .select--dropdown{
    /* Size & position */
    position: relative;
    background: inherit;
    outline: none;
    &.active{
       .dropdown--submenu{
          border: 1px solid rgba(0,0,0,0.2);
		    // overflow-y: auto;
		    // margin: 0px -1px;
           opacity: 1;
           visibility: visible;
           z-index: 3;
           transform: translateY(9px);
           transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
       }
       &:after{
          transform:rotate(180deg);
      }
  }
  &:hover{
   .select--name{color:lighten(#000, 20%);}
}
.select--name{
   padding: 11px 14px 15px 16px;
   cursor: pointer;
   .user__img{
      margin: 0 0 0 10px;
  }
}
.dropdown--submenu{
    // Size & position
    position: absolute;
    top: 100%;
    right: 0;

    // Styles
    margin: 0px -1px;
    background: #fff;
    border: 1px solid #E3E3E3;
    list-style: none;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
    border-radius: 3px;
    transform: translateY(19px);
    min-width: 186px;

    // Hiding
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    &:before{
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-left: -8px;
        top: 0;
        right: 24px;
        box-sizing: border-box;
        border: 5px solid #000;
        border-color: #fff #fff transparent transparent;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        box-shadow: 1px -1px 0px 0px rgba(0, 0, 0, 0.2);
    }
    &.left{
        right:initial;
        left:0;
        &:before{
            right: initial; 
            left: 24px;
        }
    }
    ul{
        margin: 3px 0;
    }
    li{
        &:hover{
            background: #F1F1F1;
        }
        &:last-child{
            a{border: none !important;}
        }
        a{
            display: block;
            text-decoration: none;
            margin: 0 14px;
            min-height: 31px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #f6f8f9;
        }
        i{
            margin-right: 5px;
            color: inherit;
            vertical-align: middle;
        }
    }
}
}

.dropdown--submenu{
	.media{
		padding: 9px 0;
		margin: 0 14px;
		border-bottom: 1px solid #f6f8f9;
		display: block;
		.user__img{margin: 0 5px 0 0;}
		.media-body{
			h6{font-size: 12px;}
		}
	}
	a.link{
		color: $dropdown-link;
		display: inherit;
		font-size: 8px;
	}
}



.dropdown-check{
	position:relative;
    display: flex;
    align-items: center;
    margin-left: 5px;
    .arrow{
        display:none;
        &+ label{
        	display: flex;
        	align-items: center;
        	cursor:pointer;
        	&:after{
        		content:'';
        		position: relative;
        		display: block;
        		width:5px; height:5px;
        		border-top:1px solid rgba(48,51,56, .5);
        		border-left:1px solid rgba(48,51,56, .5);
        		margin:0;
        		padding:0;
        		top: 0px;
        		right:-5px;
        		transform:rotate(-135deg);
        		transition: all .3s ease;
            }
            &:hover{
            	&:after{
            		border-top:1px solid rgba(48,51,56, 1);
            		border-left:1px solid rgba(48,51,56, 1);
                }
            }
            &+ .dropdown-menu{
                background-color:#fff;
                box-shadow: 0 0 1px 0 rgba(0,0,0,0.36), 0 2px 6px 0 rgba(0,0,0,0.10);
                border-radius:3px;
                padding: 3px 12px;
                left: -20px;
                top: 10px;
                display: none;
                position:absolute;min-width:100px;
                color: initial;
                a{
                	padding:8px 0;transition:all 0.3s ease;
                	&:hover{
                		color: $dropdown-link;
                	}
                }
                &:before{
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    margin-left: -8px;
                    top: 0;
                    left: 65%;
                    box-sizing: border-box;
                    border: 5px solid #000;
                    border-color: #fff #fff transparent transparent;
                    -webkit-transform-origin: 0 0;
                    transform-origin: 0 0;
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    box-shadow: 1px -1px 0px 0px rgba(0, 0, 0, 0.2);
                }
            }
        }
        &:checked,
        &.checked{
            &+ label{
                &:after{ }
                &+ .dropdown-menu{
                    display: block;
                    z-index: 3;
                }
            }
        }
    }        
}


/* HEADER SELECTION DROPDOWN SELECT */
.dropdown__select__list{
    margin-left: 20px;
    margin-top: 5px;
    position: relative;
    &.open{
        .dropdown__content{
            // show
            opacity: 1;
            visibility: visible;
            z-index: 99;
            transform: translateY(12px);
            transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
    .display__name{
        // font-family: $font-third-regular;
        margin: 0;
        line-height: 40px;
        height: auto;
        font-size: 20px;
        text-transform: uppercase;
        /* display: block; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: all .2s;
        &:hover{
            color: #44C7F4;
        }
    }
    .dropdown__content{
        position: absolute;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        margin: 1px 0px;
        background: #FFFFFF;
        width: 250px;

        // hide
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        transform: translateY(31px);
        &:before{
            content: "";
            position: absolute;
            top: -9px;
            left: 12px;
            width: 0px;
            height: 0px;
            margin-top: 3px;
            border-bottom: 6px solid #adb2bf;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
        }
        &:after{
            content: "";
            position: absolute;
            top: -9px;
            left: 12px;
            width: 0px;
            height: 0px;
            margin-top: 4px;
            border-bottom: 6px solid white;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
        }
        li{
            .dropdown__content__link{
                font-size: 13px;
                margin: 0 14px;
                display: block;
                min-height: 31px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #f6f8f9;
            }
            &:hover{
                background: #F1F1F1;
            }
        }
    }
}