ul.token-input-list{
	width:auto;
	padding-left: 25px;
	border: 0px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	li{
		input{width:100%;padding-left:8px !important;}
	}
	.token-input-input-token{
		float: left;
		&:hover{
			background: inherit;
		}
	}
	.token-input-token{
		float: left;
		font-weight:normal;
		background-color: #f5f5f5;
		border: 1px solid #d9d9d9;
		border-radius: 3px;
		p{font-size: 9px;}
		span{margin-left: 4px;font-size: 9px;}
	}
}

div.token-input-dropdown {
    position: absolute;
    width: 348px;
    background-color: #fff;
    overflow: hidden;
    border: 0px solid transparent;
    font-size: 11px;
    font-family: $font-base;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border: 1px solid rgba(0,0,0,.2);
    p{
    	font-size: 11px;
    	font-weight: normal;
    }
    ul{
    	max-height: 205px;
    	overflow: auto;
    	background-color: #fff;
    	li{
    		display: flex;
    		padding: 3px;
    		em{
    			font-weight: normal;
    		}
    		&.token-input-selected-dropdown-item{
    			background-color: $tokeninput-dropdown-selected;
    			color: #fff;
    		}
    	}
    }
}
