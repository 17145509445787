.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 400px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  &.loading{
    min-height: 150px;
    position: relative;
    &:after{
      content:'';
      position:absolute;
      top:0; bottom:0; left:0; right:0;
      margin:auto;
      border-radius:4px;
      width:45px; height:50px;
      background: url(#{$pathImages}/ico/ico-spinnerorange.svg) no-repeat center center;
      background-size:100%;
      animation: spin 0.6s linear infinite;
      z-index:2;
    }
    &:before{
      content:'';
      position:absolute;
      top:0; bottom:0; left:0; right:0;
      margin:auto;
      border-radius:4px;
      background:rgba(255,255,255,0.6);
      z-index:1;
    }
  }
}

.modal-header{
  background-color:#fff;
  box-shadow:0 1px 4px 0 rgba(0,0,0,0.10);
  padding: 18px;
  position:relative;
}

.modal-body {
  background-color:#F0F0F0;
  padding: 25px 20px 20px;
}

@keyframes spin{
    0%{ transform:rotate(0deg); }
    100%{ transform:rotate(360deg); }
}

.close-popup{
  width:16px;height:16px;
  position:absolute;
  top:0;right:0;
  background-color: $popup-btn-close-bg;
  color: #fff;
  &:before{
    content:'✖';
    width:inherit;height:inherit;
    top:0;bottom:0;left:0;right:0;
    margin:auto;
  }
  &:hover{
    background-color: $popup-btn-close-bg-hover;
  }
}

.modal-default-button {
  float: right;
}


.show-popup{
  transform: initial;
  backface-visibility: visible;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}