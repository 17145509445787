.card{
	background: #FFFFFF;
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
	margin: 15px 0 15px;
	position:relative;
	.card__header{
		padding:13px 15px 12px;
		border-bottom: 1px solid #EAEAEA;
		min-height: 57px;
		h6{letter-spacing:0.08px;margin:0}
	}
	.minimize{
		width:14px;
		height:14px;
		background-color: $card-minimize-bg;
		position:absolute;
		left:15px;
		top:0;
		&:before{
			content:'';
			width:8px;
			height: 2px;
			position:absolute;
			left:0;right:0;bottom:3px;top:auto;
			margin:auto;
			background-color: $card-minimize-icon;
			border: 0px solid $card-minimize-icon;
		}
		&:after{
			content:'';
			height:8px;width:2px;
			background-color: $card-minimize-icon;
			position:absolute;
			left:0;right:0;bottom:0;top:0;
			margin:auto;
			visibility: hidden;
		}
		&.collapsed{
			&:before{
				bottom:0;
				top:0;
			}
			&:after{
				visibility: visible;
			}
		}
	}
	&.form{
		z-index: 1;
	}
}
.grid{
	.card{
		margin:0;height:100%;
	}
	.card__wrapper {margin: 8px 0 7px 0;position: relative;z-index: 0;}
	.card--content{padding:16px 20px;height:inherit;}
	.card--title{
		letter-spacing:0.33px;
		color:$card-title;
		font-size:13px;
		&.s12{
			font-size: 12px;
		}
		span{
			font-family: $font-base;
			color:#A2A6AD;
			letter-spacing: 0.08px;
		}
	}
}
.card--bg-img{
	position:relative;
	background-size: cover;
	background-position: center;
	&:before{
		content: '';
		position:absolute;
		top:0;left:0;
		width: 100%;height:100%;
		background-color: rgba(0,0,0,.5);
	}
	.card--title{color:inherit;}
	.dropdown-check{
		.arrow{
			&+ label{
				&:after{
					border-color: inherit;
				}
			}
		}
	}
}
.card--bg{
	color: $color-base;
	.card--title{color:inherit;}
	.dropdown-check{
		.arrow{
			&+ label{
				&:after{
					border-color: inherit;
				}
			}
		}
	}
}
.grad-orange{
	background-image: linear-gradient(-180deg, #f95959 0%, #fc6739 100%);
}
.grad-aquablue{
	background-image: linear-gradient(0deg, #2AB8AD 0%, #03899F 100%);
}
.grad-green{
	background-image: linear-gradient(0deg, #71DF64 0%, #429321 100%);
}
.grad-darkblue{
	background-image: linear-gradient(0deg, #3B5998 0%, #154475 100%);	
}
.grad-blue{
	background-image: linear-gradient(0deg, #4099FF 0%, #258BFF 100%);	
}